export default {
  data() {
    return {
      filterLoaded: false,
      listBranch: [],
      listSpv: [],
      listAgent: [{
        id: '',
        name: 'Pilih Agen',
      }],
      modelFilter: {
        periode: 'hari',
        cabang: '',
        spv: '',
        agent: '',
      },
    }
  },
  computed: {
    uiSchemaFilter() {
      return [{
        component: 'b-form-row',
        fieldOptions: {
          class: 'align-items-center',
        },
        children: [{
          component: 'b-col',
          fieldOptions: {
            props: {
              md: 3,
            },
            class: 'mb-1 mb-md-0',
          },
          children: [{
            component: 'b-form-select',
            model: 'cabang',
            fieldOptions: {
              id: 'cabang',
              attrs: {
                placeholder: 'Pilih Cabang',
              },
              value: '',
              on: [
                'input',
              ],
              props: {
                'value-field': 'id',
                'text-field': 'name',
                options: this.listBranch,
              },
            },
          }],
        }, {
          component: 'b-col',
          fieldOptions: {
            props: {
              md: 3,
            },
            class: 'mb-1 mb-md-0',
          },
          children: [{
            component: 'b-form-select',
            model: 'spv',
            fieldOptions: {
              id: 'spv',
              attrs: {
                placeholder: 'Pilih SPV',
              },
              value: '',
              on: [
                'input',
              ],
              props: {
                'value-field': 'id',
                'text-field': 'name',
                options: this.listSpv,
              },
            },
          }],
        }, {
          component: 'b-col',
          fieldOptions: {
            props: {
              md: 3,
            },
            class: 'mb-1 mb-md-0',
          },
          children: [{
            component: 'b-form-select',
            model: 'agent',
            fieldOptions: {
              id: 'agent',
              attrs: {
                placeholder: 'Pilih Agen',
              },
              value: '',
              on: [
                'input',
              ],
              props: {
                'value-field': 'id',
                'text-field': 'name',
                options: this.listAgent,
              },
            },
          }],
        }, {
          component: 'b-col',
          fieldOptions: {
            props: {
              md: 3,
            },
            class: 'mb-1 mb-md-0',
          },
          children: [{
            component: 'b-form-select',
            model: 'periode',
            fieldOptions: {
              id: 'periode',
              attrs: {
                placeholder: 'Pilih Periode',
              },
              value: '',
              on: [
                'input',
              ],
              props: {
                options: [
                  {
                    value: 'hari',
                    text: 'Hari Ini',
                  },
                  {
                    value: 'minggu',
                    text: 'Minggu Ini',
                  },
                  {
                    value: 'bulan',
                    text: 'Bulan Ini',
                  },
                ],
              },
            },
          }],
        }],
      }]
    },
  },
  mounted() {
    // this.getListBranch()
  },
  watch: {
    modelFilter(newVal, oldVal) {
      if (newVal.spv !== undefined && newVal.spv !== '' && oldVal.spv !== newVal.spv) {
        this.getAgent(newVal.spv)
      }
    },
  },
  methods: {
    // async getListSpv() {
    //   const response = await this.$http.get('/api/User/my/dashboard/listspv?perPage=1000')
    //   this.listSpv = [{
    //     id: '',
    //     name: 'Pilih SPV',
    //   }]
    //   if (response.data.data.length > 0) {
    //     response.data.data.forEach(obj => {
    //       this.listSpv.push(obj)
    //     })
    //   }
    //   this.filterLoaded = true
    // },
    // async getListBranch() {
    //   const response = await this.$http.get('/api/User/my/dashboard/listbranch?perPage=10000')
    //   this.listBranch = [{
    //     id: '',
    //     name: 'Pilih Cabang',
    //   }]
    //   if (response.data.data.length > 0) {
    //     response.data.data.forEach(obj => {
    //       this.listBranch.push(obj)
    //     })
    //   }

    //   this.getListSpv()
    // },
    // async getAgent(spv) {
    //   const response = await this.$http.get(`/api/User/my/dashboard/listagent?id=${spv}`)
    //   this.listAgent = [{
    //     id: '',
    //     name: 'Pilih Agen',
    //   }]
    //   if (response.data.data.length > 0) {
    //     response.data.data.forEach(obj => {
    //       this.listAgent.push(obj)
    //     })
    //   }
    // },
  },
}
