<template>
  <b-row class="match-height">
    <b-col md="12" sm="12">
      <b-card v-if="data" class="card card-greetings bg-gradient-danger">
        <p>
          <b-avatar size="75" :src="data.avatar || defaultAvatar" class="bg-light">
            <feather-icon v-if="!data.username" icon="UserIcon" size="22" />
          </b-avatar>
        </p>
        <h3 class="text-white mb-25">{{ getGreetingMessage }}, {{ data.username }}</h3>
        <span>Aplikasi CMS Order Teppanku</span>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BAvatar } from 'bootstrap-vue'
import defaultAvatar from '@/assets/images/logo/teppanku.png'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
  },
  props: {
    data: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      defaultAvatar,
    }
  },
  computed: {
    getGreetingMessage() {
      const hour = new Date().getHours();
      if (hour >= 0 && hour < 10) {
        return 'Selamat Pagi';
      } else if (hour >= 10 && hour < 16) {
        return 'Selamat Siang';
      } else if (hour >= 16 && hour < 18) {
        return 'Selamat Sore';
      } else {
        return 'Selamat Malam';
      }
    }
  }
}
</script>