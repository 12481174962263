<template>
    <b-card no-body style="background-color:#f9ffff;padding:15px 20px; box-shadow:0 0 3px 0 #e2e2e2,0 2px 2px 0 #e2e2e2!important;">
        <div class="statistics-body">
            <div>{{ title }}</div>
            <h2 class="font-weight-bolder">
                {{ statisticsFormatted }}
            </h2>
        </div>
    </b-card>
</template>

<script>
    export default {
        props: ['title','statistic'],
        computed: {
            statisticsFormatted() {
                return this.statistic.toLocaleString('en-US');
            }
        }
    }
</script>


<style scoped>
    .statistics-body div {
        padding-bottom: 10px;
        font-size: 16pt;
        color: #757879;
    }
    .statistics-body h2 {
        font-size: 25pt;
        color: #0074FC;
        text-shadow: 2px 2px 4px #ccc;
    }
</style>