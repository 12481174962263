<template>
  <MainManagement />
  <!-- <Main v-else /> -->
</template>

<script>
import Main from './Main.vue'
import MainManagement from './Main_Management.vue'

export default {
  components: {
    Main,
    MainManagement,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
}
</script>
