<template>
  <section id="dashboard-main">
    <dashboard-greetings :data="data" />

    <b-card v-if="activeData != null" bg-variant="light" :title="`Statistik ${rangeTitle(range)}`"
      style="margin-bottom:20px;box-shadow: 0 0 3px 0 rgba(0,0,0,.07),0 2px 2px 0 rgba(0,0,0,.04)!important;">
      <b-row class="match-height">
        <b-col md="3" sm="6">
          <card-statistics title="Callback" :statistic="activeData.fuim"></card-statistics>
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="Message" :statistic="activeData.mess"></card-statistics>
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="No Answer" :statistic="activeData.noas"></card-statistics>
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="Tidak Diangkat" :statistic="activeData.noas1"></card-statistics>
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="No Tidak Aktif" :statistic="activeData.noas2"></card-statistics>
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="Wrong Number" :statistic="activeData.noas3"></card-statistics>
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="Payment" :statistic="activeData.pay"></card-statistics>
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="Promise To Pay" :statistic="activeData.ptp"></card-statistics>
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="Sudah Visit" :statistic="activeData.vt"></card-statistics>
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="Remedial" :statistic="activeData.rem"></card-statistics>
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="Lainnya" :statistic="activeData.ll"></card-statistics>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="d-flex justify-content-center" md="12" sm="12">
          <b-form-group class="mb-0">
            <b-form-radio-group v-model="range">
              <b-form-radio value="hari">
                {{ rangeTitle('hari') }}
              </b-form-radio>
              <b-form-radio value="minggu">
                {{ rangeTitle('minggu') }}
              </b-form-radio>
              <b-form-radio value="bulan">
                {{ rangeTitle('bulan') }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

  </section>
</template>

<script>
import { getUserData } from '@/auth/utils'
import DashboardGreetings from './DashboardGreetings.vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import CardStatistics from './CardStatistics.vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    DashboardGreetings,
    StatisticCardWithAreaChart,
    VueApexCharts,
    StatisticCardHorizontal,
    StatisticCardVertical,
    CardStatistics
  },
  data() {
    return {
      data: {},
      range: 'hari',
      options: {
        chart: {
          id: 'chart-1'
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
        }
      },
      series: [{
        name: 'series-1',
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      }],
      statistics: null,
      activeData: null
    }
  },
  created() {
    const userData = getUserData()
    this.data = userData
  },
  mounted() {
    // this.getDashboardData()
  },
  watch: {
    range(newVal, oldVal) {
      if (this.statistics != null) {
        switch (newVal) {
          case 'hari':
            this.activeData = this.statistics.today
            break;
          case 'minggu':
            this.activeData = this.statistics.thisWeek
            break;
          case 'bulan':
            this.activeData = this.statistics.thisMonth
            break;
        }
      }
    }
  },
  methods: {
    rangeTitle(rtext) {
      switch (rtext) {
        case 'hari':
          return 'Hari Ini'
        case 'minggu':
          return 'Minggu Ini'
        case 'bulan':
          return 'Bulan Ini'
      }
    },
    // async getDashboardData() {
    //   const response = await this.$http.get('/api/User/my/dashboard')
    //   this.statistics = response.data.data.length ? response.data.data[0] : null
    //   this.activeData = response.data.data.length ? response.data.data[0].today : null
    // }
  }
}
</script>

<style scoped>
.card-title {
  font-size: 20pt;
  padding: 20px 0 10px;
}
</style>
