<template>
  <section id="dashboard-main">
    <dashboard-greetings :data="data" />
    <b-card v-if="activeData != null" bg-variant="light"
      style="margin-bottom:20px;box-shadow: 0 0 3px 0 rgba(0,0,0,.07),0 2px 2px 0 rgba(0,0,0,.04)!important;">
      <div class="mb-2">
        <div class="card-title mb-1">
          {{ `Statistik ${rangeTitle(modelFilter.periode)}` }}
        </div>
        <div class="d-flex flex-column flex-md-row items-center justify-end">
          <div v-if="filterLoaded" class="flex-grow-1 mr-0 mr-md-1">
            <vue-form-json-schema v-model="modelFilter" :schema="schemaFilter" :ui-schema="uiSchemaFilter" />
          </div>
          <b-button variant="primary" @click="getDashboardData">
            <feather-icon icon="SearchIcon" />
            Filter
          </b-button>
        </div>
      </div>
      <b-row class="match-height">
        <b-col md="3" sm="6">
          <card-statistics title="Jumlah Kewajiban" :statistic="activeData.kewajibanAmount" />
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="Jumlah Dibayar" :statistic="activeData.payAmount" />
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="Callback" :statistic="activeData.fuim" />
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="Message" :statistic="activeData.mess" />
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="No Answer" :statistic="activeData.noas" />
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="Tidak Diangkat" :statistic="activeData.noas1" />
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="No Tidak Aktif" :statistic="activeData.noas2" />
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="Wrong Number" :statistic="activeData.noas3" />
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="Payment" :statistic="activeData.pay" />
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="Promise To Pay" :statistic="activeData.ptp" />
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="Sudah Visit" :statistic="activeData.vt" />
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="Remedial" :statistic="activeData.rem" />
        </b-col>
        <b-col md="3" sm="6">
          <card-statistics title="Lainnya" :statistic="activeData.ll" />
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import { getUserData } from '@/auth/utils'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'
import schemaFilter from './schema/SelectSchema.json'
import uiSchemaMixin from './schema/SelectUiSchema.js'
import CardStatistics from './CardStatistics.vue'
import DashboardGreetings from './DashboardGreetings.vue'

export default {
  components: {
    DashboardGreetings,
    StatisticCardWithAreaChart,
    VueApexCharts,
    StatisticCardHorizontal,
    StatisticCardVertical,
    CardStatistics,
    vSelect,
  },
  mixins: [uiSchemaMixin],
  data() {
    return {
      data: {},
      options: {
        chart: {
          id: 'chart-1',
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      series: [{
        name: 'series-1',
        data: [30, 40, 45, 50, 49, 60, 70, 91],
      }],
      statistics: null,
      activeData: null,
      schemaFilter,
    }
  },
  created() {
    const userData = getUserData()
    this.data = userData
  },
  mounted() {
    // this.getDashboardData()
    // this.getListBranch()
  },
  methods: {
    rangeTitle(rtext) {
      switch (rtext) {
        case 'hari':
          return 'Hari Ini'
        case 'minggu':
          return 'Minggu Ini'
        case 'bulan':
          return 'Bulan Ini'
        default:
          return 'Hari Ini'
      }
    },
    // async getDashboardData() {
    //   let pid = 1
    //   if (this.modelFilter.periode === 'minggu') {
    //     pid = 2
    //   } else if (this.modelFilter.periode === 'bulan') {
    //     pid = 3
    //   }

    //   const response = await this.$http.get('/api/User/my/dashboard', {
    //     params: {
    //       periodId: pid,
    //       branchId: this.modelFilter.cabang,
    //       spvId: this.modelFilter.spv,
    //       agentId: this.modelFilter.agent,
    //     },
    //   })
    //   this.statistics = response.data.data.length ? response.data.data[0] : null
    //   this.activeData = response.data.data.length ? response.data.data[0].data : null
    // },
  },
}
</script>

<style scoped>
.card-title {
  font-size: 20pt;
  padding-right: 20px;
  margin-bottom: 0;
}
</style>
